import { defineStore } from 'pinia';
import { taxonomyList } from '~/pinkyellow/satanic-api';

export const useTaxonomyStore = defineStore('taxonomy', {
  state: () => ({
    taxonomies: {},
  }),

  getters: {
    getTerm(state) {
      return (taxonomy, slug) => {
        const taxonomyList = state.taxonomies[taxonomy];

        if (!taxonomyList) {
          return '';
        }

        const term = taxonomyList.filter((term) => term.slug === slug[0]).shift();
        return term ? term.title : '';
      };
    },

    getTaxonomy(state) {
      return (taxonomy) => {
        return state.taxonomies[taxonomy];
      };
    },

    getFilteredTaxonomy(state) {
      return (taxonomy) => {
        return state.taxonomies['filtered-' + taxonomy];
      };
    },
  },

  actions: {
    async fetchTaxonomy({ taxonomy }) {
      this.taxonomies[taxonomy] = await taxonomyList({ taxonomy });
    },

    async fetchTaxonomyFiltered({ taxonomy, filters }) {
      this.taxonomies['filtered-' + taxonomy] = await taxonomyList({ taxonomy, filters: filters });
    },
  },
});
