import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

const config = {
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom right',
      equalWeightButtons: false,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: false,
      flipButtons: false,
    },
  },
  categories: {
    necessary: {
      readOnly: true,
    },
    functionality: {
      readOnly: true,
    },
    analytics: {
      enabled: true,
    },
    marketing: {
      enabled: true,
    },
  },
  language: {
    default: 'en',
    autoDetect: 'browser',
    translations: {
      en: {
        consentModal: {
          title: 'Did anyone say Cookie?',
          description:
            'We use cookies to ensure you get the best experience on our website. You can manage your preferences below.',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage preferences',
          footer: '<a href="/privacy-policy" target="_blank">Privacy Policy</a>\n<a href="/terms-of-use" target="_blank">Terms of Use</a>',
        },
        preferencesModal: {
          title: 'Consent Preferences Center',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Save preferences',
          closeIconLabel: 'Close modal',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Cookie Usage',
              description:
                'Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.',
            },
            {
              title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
              description:
                'Strictly necessary cookies are essential for the website to function properly and ensure the security of your data. They are activated by default because your consent is not required for their use.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Functionality Cookies <span class="pm__badge">Always Enabled</span>',
              description:
                'Functionality cookies allow the website to remember your choices (such as your language, or the region you are in) and provide enhanced, more personal features. These cookies are used to remember your preferences and therefore cannot be disabled.',
              linkedCategory: 'functionality',
            },
            {
              title: 'Analytics Cookies',
              description:
                'Analytics cookies help us to understand how visitors interact with the website. We use this information to improve the user experience and the performance of our website.',
              linkedCategory: 'analytics',
            },
            {
              title: 'Marketing Cookies',
              description:
                'Marketing cookies are used to track visitors across websites. This helps us display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
              linkedCategory: 'marketing',
            },
            // {
            //   title: 'More information',
            //   description:
            //     'For any query in relation to my policy on cookies and your choices, please <a class="cc__link" href="#yourdomain.com">contact me</a>.',
            // },
          ],
        },
      },
    },
  },
};

export default defineNuxtPlugin(async () => {
  await CookieConsent.run(config);

  return {
    provide: {
      CC: CookieConsent,
    },
  };
});
