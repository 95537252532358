import revive_payload_client_4sVQNw7RlN from "/vercel/path0/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/vercel/path0/nuxt/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import cookieconsent_client_hWvU7DcGQW from "/vercel/path0/nuxt/plugins/cookieconsent.client.js";
import fathom_client_6QHXGUB7wS from "/vercel/path0/nuxt/plugins/fathom.client.js";
import flare_client_TQnyawnbiN from "/vercel/path0/nuxt/plugins/flare.client.js";
import paginator_y0WKm3tLet from "/vercel/path0/nuxt/plugins/paginator.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  cookieconsent_client_hWvU7DcGQW,
  fathom_client_6QHXGUB7wS,
  flare_client_TQnyawnbiN,
  paginator_y0WKm3tLet
]