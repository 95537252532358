<script setup>
  import gsap from 'gsap';

  // --

  const spotlightRef = ref();
  const logoActive = ref();
  const containerRef = ref();
  const logoRef = ref();

  const emit = defineEmits(['complete']);

  onMounted(() => {
    const spotlights = spotlightRef.value.querySelectorAll('img');

    gsap.killTweensOf(spotlights);
    gsap.to(spotlights[0], {
      opacity: 1.0,
      duration: 0.02,
      delay: 0.3,
      ease: 'sine.in',
      onComplete: () => {
        gsap.to(spotlights[1], {
          opacity: 1.0,
          duration: 0.02,
          delay: 0.17,
          ease: 'sine.in',
          onComplete: () => {
            gsap.to(spotlights[0], {
              opacity: 0.3,
              duration: 0.02,
              delay: 0.05,
              ease: 'sine.in',
              onComplete: () => {
                gsap.to(spotlights[1], {
                  opacity: 0.3,
                  duration: 0.02,
                  delay: 0.05,
                  ease: 'sine.in',
                  onComplete: () => {
                    gsap.to(spotlights[2], {
                      opacity: 0.8,
                      duration: 0.01,
                      delay: 0.12,
                      ease: 'sine.inOut',
                      onComplete: () => {
                        setTimeout(() => {
                          logoActive.value = true;
                        }, 100.0);

                        gsap.fromTo(
                          spotlights[0],
                          { opacity: 0.0 },
                          {
                            opacity: 0.7,
                            duration: 0.3,
                            delay: 0.0,
                            ease: 'sine.inOut',
                          },
                        );
                        gsap.fromTo(
                          spotlights[1],
                          { opacity: 0.0 },
                          {
                            opacity: 0.7,
                            duration: 0.8,
                            delay: 0.15,
                            ease: 'sine.inOut',
                          },
                        );
                        gsap.to(spotlights[3], {
                          opacity: 0.7,
                          duration: 0.9,
                          delay: 0.1,
                          ease: 'sine.inOut',
                        });
                        gsap.to(spotlights[2], {
                          opacity: 0.7,
                          duration: 0.9,
                          delay: 0.2,
                          ease: 'sine.inOut',
                          onComplete: () => {
                            gsap.to(spotlights[0], {
                              opacity: 0.0,
                              duration: 1.15,
                              ease: 'sine.inOut',
                            });
                            gsap.to(spotlights[1], {
                              opacity: 0.0,
                              duration: 1.15,
                              delay: 0.45,
                              ease: 'sine.inOut',
                            });

                            gsap.to(logoRef.value, {
                              opacity: 0.0,
                              duration: 0.76,
                              delay: 0.55,
                              ease: 'sine.in',
                            });

                            gsap.to(containerRef.value, {
                              opacity: 0.0,
                              duration: 0.56,
                              delay: 1.0,
                              ease: 'sine.in',
                              onComplete: () => {
                                emit('complete');
                              },
                            });
                          },
                        });
                      },
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="containerRef" class="loader" :class="{ logoActive }">
    <div ref="logoRef"><IconGlory /></div>
    <div ref="spotlightRef">
      <img :src="useCdn().generateStaticAsset('images/spotlight_01.png')" alt="glory" />
      <img :src="useCdn().generateStaticAsset('images/spotlight_02.png')" alt="glory" />
      <img :src="useCdn().generateStaticAsset('images/spotlight_03.png')" alt="glory" />
      <img :src="useCdn().generateStaticAsset('images/spotlight_04.png')" alt="glory" />
    </div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .loader {
    position: fixed;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: $layer-loader;
    background-color: $black;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;

      z-index: 1;

      width: 50vw;
      max-width: 220px;
      height: auto;

      transform: translate(-50%, -50%);

      -webkit-mask-image: linear-gradient(90deg, $black 60%, transparent 80%);
      mask-image: linear-gradient(90deg, $black 60%, transparent 80%);

      -webkit-mask-size: 700% 100%;
      mask-size: 700% 100%;

      -webkit-mask-position: right;
      mask-position: right;

      @keyframes clip-fade {
        100% {
          -webkit-mask-position: left;
          mask-position: left;
        }
      }
    }

    &.logoActive {
      svg {
        animation: clip-fade 3.2s $quadInOut;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;

      object-fit: cover;

      opacity: 0;
    }
  }
</style>
