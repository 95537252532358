<script setup>
  const { delay, fadeOnly, immediate } = defineProps({ delay: Number, immediate: Boolean, fadeOnly: Boolean });

  // --

  const scrollContainer = ref();
  const active = ref(immediate);

  onNavigate(() => {
    activate();
  });

  onMounted(() => {
    activate();
  });

  function activate() {
    setTimeout(
      () =>
        scrollContainer.value &&
        scrollContainer.value.getBoundingClientRect().top < window.innerHeight &&
        (active.value = true),
      200.0,
    );
  }

  if (useScrollAnimation().value) {
    useTelescope(scrollContainer, {
      visible: (target) => {
        active.value = true;
        1;
      },
      partial: (target, rect) => {
        rect.top > 0.0 && (active.value = true);
      },
      hidden: (target, rect) => {
        rect.top > 0.0 && (active.value = false);
      },
    });
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <div
    ref="scrollContainer"
    class="scrollContainer"
    :class="{ active, fadeOnly, enabled: useScrollAnimation().value }"
    :style="`--delay: ${delay || 0.0}s`"
  >
    <slot />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .scrollContainer {
    &.enabled {
      transition:
        transform 1.08s $expoOut,
        opacity 0.68s $sineOut;

      &:not(.fadeOnly) {
        transform: translate3d(0, px(80), 0);
      }

      opacity: 0;

      &.active {
        transform: translate3d(0, 0, 0);
        transition-delay: var(--delay);
        opacity: 1;
      }
    }
  }
</style>
