<template>
  <svg width="125" height="27" viewBox="0 0 125 27" fill="none" xmlns="http://www.w3.org/2000/svg" title="glory">
    <path
      d="M25.2043 1.07813L22.4952 5.6597H15.5938H15.1216C13.1332 5.6597 9.79434 5.6597 7.8888 8.74999C6.43893 11.1029 6.44722 15.7011 7.8888 17.8551C9.76948 20.7632 12.6775 20.7632 15.0221 20.7632H18.9906V15.4359H12.0396V10.9206H24.7072V25.3116H13.1C9.27238 25.3116 5.32046 24.9885 2.81841 21.136C0.200364 17.1261 0.167224 9.4045 2.75213 5.25374C5.29561 1.07812 10.1423 1.07813 13.3486 1.07813H25.1961"
      fill="white"
    />
    <path
      d="M70.9126 4.6545C68.2697 0.59488 64.1438 0.180632 60.1007 0.164062C60.0427 0.164062 59.9765 0.164062 59.9102 0.164062H59.8605C56.4139 0.164062 51.3767 0.263482 48.6924 4.64622C45.9169 9.11181 45.95 17.405 48.7669 21.7215C51.4098 25.7894 55.544 26.1954 59.5788 26.2119C59.6368 26.2119 59.7031 26.2119 59.7693 26.2119H59.819C63.2656 26.2119 68.3028 26.1125 70.9872 21.7298C73.7626 17.2642 73.7295 8.97096 70.9126 4.6545ZM66.0493 18.4075C64.558 21.0918 61.7494 21.1498 59.8356 21.1498H59.7031C57.4496 21.1416 55.1546 20.8847 53.6799 18.3992C52.114 15.7646 52.0975 10.6859 53.6385 7.9602C55.1298 5.27588 57.9384 5.21788 59.8522 5.21788H59.9848C62.2383 5.22617 64.5332 5.47471 66.0079 7.96848C67.5738 10.6031 67.5903 15.6818 66.0493 18.4075Z"
      fill="white"
    />
    <path d="M34.5997 1.07812V21.2023H46.2234L43.7131 25.3116H28.8748V1.07812H34.5914" fill="white" />
    <path
      d="M91.0201 1.07812C93.6547 1.07812 95.8336 1.26039 97.822 3.58846C99.6447 5.72598 99.711 9.48735 99.1725 11.4757C98.6422 13.4144 97.5983 14.566 95.6928 15.3365C94.4252 15.8916 93.6298 15.9413 92.7185 15.9993H92.5942L91.8403 16.0573L92.2877 16.6621L98.6091 25.3116H92.0226L82.4369 11.9148L82.4037 11.8651H88.0789H88.5512C90.3241 11.8651 92.122 11.7491 93.0002 10.3656C93.6878 9.33822 93.6298 7.62323 93.1245 6.7616C92.2628 5.34487 90.1336 5.17917 88.6092 5.17917C88.4186 5.17917 88.2363 5.17917 88.0789 5.17917C87.9629 5.17917 87.8552 5.17917 87.7641 5.17917H81.6912V25.3033H75.9746V1.07812H90.6638H91.0201Z"
      fill="white"
    />
    <path
      d="M124.251 1.07812L114.889 14.3672L114.815 14.4749V25.3116H109.131V14.5412L109.056 14.4335L99.3879 1.07812H105.867L111.625 8.80798L111.973 9.27194L112.304 8.79141L117.59 1.07812H124.259"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(125);
  }
</style>
