<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <GridItem :from="3" :to="10" class="center-wrap">
    <slot />
  </GridItem>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .center-wrap {
    @include break-medium {
      @include column(3, 10);
    }

    @include break-small {
      @include column(2, 11);
    }

    @include break-phone {
      @include column(1, 12);
    }
  }
</style>
