const theme = ref('');

export default () => {
  function setTheme(value) {
    value || console.error('useTheme() - No theme specified');
    theme.value = value;
  }

  return { theme, setTheme };
};
