import { default as _91_46_46_46page_93bbvW8s9pnrMeta } from "/vercel/path0/nuxt/pages/[...page].vue?macro=true";
import { default as _91event_930VetJtiUrYMeta } from "/vercel/path0/nuxt/pages/events/[event].vue?macro=true";
import { default as _91fighter_934vASugs1LpMeta } from "/vercel/path0/nuxt/pages/fighters/[fighter].vue?macro=true";
import { default as _91news_9374pKeVLZxFMeta } from "/vercel/path0/nuxt/pages/news/[news].vue?macro=true";
import { default as searchii4Hqk8PKyMeta } from "/vercel/path0/nuxt/pages/search.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93bbvW8s9pnrMeta?.name ?? "page",
    path: _91_46_46_46page_93bbvW8s9pnrMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93bbvW8s9pnrMeta || {},
    alias: _91_46_46_46page_93bbvW8s9pnrMeta?.alias || [],
    redirect: _91_46_46_46page_93bbvW8s9pnrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91event_930VetJtiUrYMeta?.name ?? "events-event",
    path: _91event_930VetJtiUrYMeta?.path ?? "/events/:event()",
    meta: _91event_930VetJtiUrYMeta || {},
    alias: _91event_930VetJtiUrYMeta?.alias || [],
    redirect: _91event_930VetJtiUrYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91fighter_934vASugs1LpMeta?.name ?? "fighters-fighter",
    path: _91fighter_934vASugs1LpMeta?.path ?? "/fighters/:fighter()",
    meta: _91fighter_934vASugs1LpMeta || {},
    alias: _91fighter_934vASugs1LpMeta?.alias || [],
    redirect: _91fighter_934vASugs1LpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/fighters/[fighter].vue").then(m => m.default || m)
  },
  {
    name: _91news_9374pKeVLZxFMeta?.name ?? "news-news",
    path: _91news_9374pKeVLZxFMeta?.path ?? "/news/:news()",
    meta: _91news_9374pKeVLZxFMeta || {},
    alias: _91news_9374pKeVLZxFMeta?.alias || [],
    redirect: _91news_9374pKeVLZxFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/news/[news].vue").then(m => m.default || m)
  },
  {
    name: searchii4Hqk8PKyMeta?.name ?? "search",
    path: searchii4Hqk8PKyMeta?.path ?? "/search",
    meta: searchii4Hqk8PKyMeta || {},
    alias: searchii4Hqk8PKyMeta?.alias || [],
    redirect: searchii4Hqk8PKyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/search.vue").then(m => m.default || m)
  }
]