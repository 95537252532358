export function tick(event, priority) {
  Clock.tick(event, priority);
}

export function tock(event) {
  Clock.tock(event);
}

// --

const Clock = new (class {
  // ---------------------------------------------------------------------------

  _time = 0.0;
  _then = performance.now();

  _events = [];
  _length = 0;

  // --

  constructor() {
    if (typeof window === 'undefined') return;

    document.addEventListener(
      'visibilitychange',
      () => (this._then = performance.now()),
    );

    requestAnimationFrame(this.#tick);
  }

  // ---------------------------------------------------------------------------

  tick(event, priority = 0.0) {
    this._events.push([event, priority]);
    this._events.sort((a, b) => a[1] - b[1]);

    this._length = this._events.length;
  }

  tock(event) {
    this._events = this._events.filter(([e]) => e !== event);
    this._length = this._events.length;
  }

  // ---------------------------------------------------------------------------

  #tick = () => {
    requestAnimationFrame(this.#tick);

    const now = performance.now();
    const interval = (now - this._then) * 0.001;

    this._time += interval;
    this._then = now;

    for (let i = 0; i < this._length; i++) {
      this._events[i][0](interval, this._time);
    }
  };
})();
