class Telescope {
  // ---------------------------------------------------------------------------

  _objects = [];
  _length = 0;

  // --

  constructor() {
    if (typeof window === 'undefined') return;

    this._detector = new IntersectionObserver(this.#detect, {
      threshold: [0.0, 1.0],
    });
    this._observer = new IntersectionObserver(this.#observe);
    this._locator = new ResizeObserver(this.#locate);

    addEventListener('resize', this.#location);
    addEventListener('orientationchange', this.#location);
    addEventListener('scroll', this.#location);
  }

  // ---------------------------------------------------------------------------

  detect(object, { hidden = () => {}, partial = () => {}, visible = () => {} }) {
    object.telescope = {
      ...object.telescope,
      detect: [hidden, partial, visible],
    };

    this._detector.observe(object);
  }

  // --

  observe(object, observe) {
    object.telescope = { ...object.telescope, observe };

    this._observer.observe(object);
    this._locator.observe(object);
  }

  ignore(object) {
    this._ignore(object);

    this._detector.unobserve(object);
    this._observer.unobserve(object);
    this._locator.unobserve(object);

    delete object.telescope;
  }

  // --

  _observe(object) {
    this._objects.push(object);
    this._length = this._objects.length;
  }

  _ignore(object) {
    this._objects = this._objects.filter((o) => o !== object);
    this._length = this._objects.length;
  }

  // ---------------------------------------------------------------------------

  #detect = (objects) => {
    for (let i = 0; i < objects.length; i++)
      objects[i].target.telescope.detect[objects[i].intersectionRatio ? 1 + (objects[i].intersectionRatio | 0) : 0](
        objects[i].target,
        objects[i].boundingClientRect,
      );
  };

  #observe = (objects) => {
    for (let i = 0; i < objects.length; i++)
      objects[i].isIntersecting ? this._observe(objects[i].target) : this._ignore(objects[i].target);

    this.#locate(objects);
  };

  // --

  #locate = (objects) => {
    for (let i = 0; i < objects.length; i++) {
      const { bottom, height } = objects[i].target.getBoundingClientRect();
      objects[i].target.telescope.observe(Math.min(Math.max(0.0, 1.0 - bottom / (innerHeight + height)), 1.0));
    }
  };

  #location = () => {
    for (let i = 0; i < this._length; i++) {
      const { bottom, height } = this._objects[i].getBoundingClientRect();
      this._objects[i].telescope.observe(1.0 - bottom / (innerHeight + height));
    }
  };
}

export default new Telescope();
