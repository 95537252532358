<script setup>
  import { useBaseStore } from '~/stores/base';
  import { smoothie } from '~/composables/pinkyellow/useSmoothie';

  useSweet16(16.0);
  useCookieConsent();
  // useScrollAnimation().value = false;

  // --

  useSmoothie({
    flavour: (t) => 1.0 - Math.pow(2.0, -10.0 * t), // easing

    slurp: 1.17, // scroll duration in seconds
    limit: 120.0, // maximum amount of pixels per slurp

    wheel: 1.0,
    touch: 0.0,
    slide: 0.0,
  });

  const scrollPosition = ref(0.0);
  const scrollSpeed = ref(0.0);

  onNavigate(() => {
    smoothie.value._finish();
    // setTimeout(() => window.scrollTo(0.0, 0.0), 200.0);
  });

  // --

  onServerPrefetch(async () => {
    const baseStore = useBaseStore();
    const taxonomyStore = useTaxonomyStore();

    await baseStore.fetchLayout();
    await taxonomyStore.fetchTaxonomy({
      taxonomy: 'categories',
    });
    await taxonomyStore.fetchTaxonomy({
      taxonomy: 'weight_classes',
    });
    await taxonomyStore.fetchTaxonomyFiltered({
      taxonomy: 'weight_classes',
      filters: [{ field: 'filterable', condition: 'equals', value: true }],
    });
  });

  onMounted(() => {
    useDevice().isMobileOrTablet && document.body.classList.add('touch');
  });

  // --

  onTick((dt) => {
    const position = window.scrollY;
    scrollSpeed.value = position - scrollPosition.value;

    // might come in handy later!
    // isScrolling.value = Math.abs(scrollSpeed.value) > 0.0001;
    // scrollSpeed.value !== 0.0 &&
    //   (scrollDirection.value = progress > scrollProgress.value ? 1.0 : -1.0);

    scrollPosition.value = position;
  });

  // --

  const loadComplete = ref(false);

  const { theme } = useTheme();
  watch(theme, (val) => {
    document.body.classList.remove('dark', 'light');
    document.body.classList.add(val);
  });

  provide('scrollSpeed', scrollSpeed);
  provide('loadComplete', loadComplete);
</script>

<!----------------------------------------------------------------------------->

<template>
  <Pillow />
  <Title>Glory Kickboxing</Title>

  <!-- <Fonts /> -->

  <Body>
    <Loader v-if="!loadComplete" @complete="loadComplete = true" />
    <slot />
  </Body>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss">
  // .page-enter-active,
  // .page-leave-active {
  //   transition: opacity 0.42s $sineInOut;
  // }
  // .page-enter-from,
  // .page-leave-to {
  //   transition-duration: 0.18s;
  //   opacity: 0;
  // }
</style>
