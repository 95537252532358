import { defineStore } from 'pinia';
import { layout } from '~/pinkyellow/satanic-api';

export const useBaseStore = defineStore('base', {
  state: () => ({
    base: [],
  }),

  getters: {
    getNavBar(state) {
      let nav = {
        main: [],
        shop: false,
        tickets: false,
      };
      for (let i = 0; i < state.base.nav.main_menu.length; i++) {
        let type = state.base.nav.main_menu[i].page.type.value;
        if (!type || type === 'default') {
          nav['main'].push(state.base.nav.main_menu[i].page);
        } else if (!nav[type]) {
          nav[type] = state.base.nav.main_menu[i].page;
        }
      }
      return nav;
    },

    getFooter(state) {
      return {
        mainFooter: state.base.nav.main_footer,
        secondaryFooter: state.base.nav.secondary_footer,
        bottomFooter: state.base.nav.bottom_footer,
      };
    },

    getPresets(state) {
      return state.base.image.sizes;
    },

    getCdnCropUrl(state) {
      return state.base.image.cdn_crop_url;
    },

    getCdnBaseUrl(state) {
      return state.base.image.cdn_base_url;
    }
  },

  actions: {
    async fetchLayout() {
      this.base = await layout({
        max_depth: 1,
        fields: ['title', 'url', 'type'],
      });
    },
  },
});
