export default (callback) => {
  const route = useRoute();
  let path;

  watch(
    () => route.path,
    () => {
      path && (path === route.path || callback());
      path = route.path;
    },
    { immediate: true, deep: true },
  );
};
