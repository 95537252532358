import md5 from 'md5';
import moment from 'moment';

const generatePicture = (image, preset) => {
  const store = useBaseStore();

  const imagePresets = store.getPresets;
  const cdnBaseUrl = store.getCdnCropUrl;

  const imagePreset = Object.assign({}, imagePresets[preset]);
  const placeholderPreset = Object.assign({}, imagePresets[`${preset}-placeholder`]);

  return {
    imageUrl: generateCdnPath(imagePreset, image, cdnBaseUrl),
    placeholderUrl: generateCdnPath(placeholderPreset, image, cdnBaseUrl),
    alt: image.alt,
    width: imagePreset.w,
    height: imagePreset.h,
  };
};

function generateCdnPath(preset, image, baseUrl) {
  if (preset.fit === 'crop_focal') {
    preset.fit = image.focus === '50-50-1' ? 'crop-50-50' : 'crop-' + image.focus;
  }

  const queryParams = new URLSearchParams(preset).toString();
  const filename = md5(image.basename + '?' + queryParams);

  const extension = preset.fm ? preset.fm : image.extension;

  let fullPath = '';

  if (moment(image.last_modified).isAfter('2024-06-08T00:00:00Z')) {
    fullPath = '/containers/' + image.container.handle + '/' + image.path + '/' + filename + '/' + image.path.replace(/\.[^/.]+$/, '') + '.' + extension;
  } else {
    fullPath = '/containers/' + image.container.handle + '/' + image.path + '/' + filename + '.' + extension;
  }

  return baseUrl + fullPath;
}

function generateStaticAsset(path) {
  const store = useBaseStore();

  const cdnBaseUrl = store.getCdnBaseUrl;

  return cdnBaseUrl + '/static/' + path;
}

function generateFlag(isoCode) {
  const store = useBaseStore();

  const cdnBaseUrl = store.getCdnBaseUrl;

  return cdnBaseUrl + '/assets/iso-flags/' + isoCode + '.png';
}

function generateAnimFlag(isoCode) {
  const store = useBaseStore();

  const cdnBaseUrl = store.getCdnBaseUrl;

  return cdnBaseUrl + '/assets/iso-flags/animated/' + isoCode + '.mp4';
}

export default () => ({ generatePicture, generateStaticAsset, generateFlag, generateAnimFlag });
