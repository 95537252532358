import { tick, tock } from '~/pinkyellow/ticktock';

// --

export default (callback) => {
  onMounted(() => {
    tick(callback);
  });

  onBeforeUnmount(() => {
    tock(callback);
  });
};
