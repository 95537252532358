export default () => {
  onMounted(() => {
    const cookies = document.getElementById('cc-main');

    if (cookies) {
      // smooth scroll override
      cookies.addEventListener('wheel', (e) => {
        e.stopPropagation();
      });

      // cookie
      const title = cookies.querySelector('.cm__title');
      title.style.display = 'flex';
      title.style.alignItems = 'center';

      const face = document.createElement('div');
      face.style.width = '44px';
      face.style.marginLeft = '18px';
      face.style.marginTop = '-10px';

      title.appendChild(face);

      const faceSad = document.createElement('img');
      faceSad.src = useCdn().generateStaticAsset('images/cookie.png');
      faceSad.style.display = 'block';

      const faceHappy = document.createElement('img');
      faceHappy.src = useCdn().generateStaticAsset('images/cookie_smile.png');
      faceHappy.style.display = 'none';

      face.appendChild(faceSad);
      face.appendChild(faceHappy);

      // cookie hover
      const acceptButton = cookies.querySelector('.cm__btn-group').childNodes[0];

      acceptButton.addEventListener('mouseenter', () => {
        faceSad.style.display = 'none';
        faceHappy.style.display = 'block';
      });

      acceptButton.addEventListener('mouseleave', () => {
        faceSad.style.display = 'block';
        faceHappy.style.display = 'none';
      });
    }
  });
};
