<script setup>
  const { data } = defineProps(['data']);
  const { title, body, button_title, button_link } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <div>
    <Text>
      <h2>{{ title }}</h2>
      <div v-html="body"></div>
    </Text>
    <Button v-if="button_link && button_title" :to="button_link">{{ button_title }}</Button>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    margin-top: px(64);
  }

  .column {
    @include break-min-small {
      &.left {
        @include column(2, 5);
      }

      &.right {
        @include column(8, 11);
      }
    }

    @include break-small {
      @include column(2, 11);
    }

    @include break-phone {
      @include column(1, 12);
    }
  }
</style>
