<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <App>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </App>
</template>
