<script setup>
  const { to, color, outline } = defineProps({ to: String, color: String, outline: Boolean });
  const target = useLinkTarget(to);

  // --

  const colorClass = ref(color);

  function changeColor(color) {
    colorClass.value = color;
  }

  defineExpose({ changeColor });
</script>

<!----------------------------------------------------------------------------->

<template>
  <NuxtLink :to="to" :target="target" :class="[colorClass, outline ? 'outline' : '']"
    ><span><slot /></span>
  </NuxtLink>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    white-space: nowrap;

    @include hitbox(0px);
    @include industry-black(px(14));

    height: px(48);
    padding: 0 px(52);

    cursor: pointer;

    @include fade('color, background-color, border-color', 0.37s);
    @include hover {
      @include fade('color, background-color, border-color', 0.15s);
    }

    @include break-phone {
      width: 100%;
      padding: 0;
    }

    border-radius: px(2);

    span {
      margin-top: $label-offset;
    }

    &:not(.outline) {
      background-color: $red;
      color: $white;

      @include hover {
        background-color: $dark-red;
      }

      &.black {
        background-color: $black;

        @include hover {
          background-color: $white;
          color: $black;
        }
      }

      &.white {
        color: $black;
        background-color: $white;

        @include hover {
          background-color: $red;
          color: $white;
        }
      }
    }

    &.outline {
      border: px(1) solid $white-40;

      @include hover {
        border-color: $white;
      }

      &.black {
        border-color: $grey-2;
        color: $black;

        @include hover {
          border-color: $black;
        }
      }
    }
  }
</style>
