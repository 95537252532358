<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <div><slot /></div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    :deep(h2),
    :deep(h3) {
      @include roboto-bold(px(20));
      line-height: 1.3;

      margin-bottom: px(32);

      @include break-small {
        font-size: px(16);
        margin-bottom: px(20);
      }
    }

    :deep(a) {
      @include hitbox(0.4rem, 0.4rem);
      text-decoration: underline;

      @include hover {
        color: $red;
      }
    }

    :deep(p),
    :deep(ol),
    :deep(ul) {
      margin-bottom: px(26);

      @include break-small {
        margin-bottom: px(22);
      }
    }

    :deep(ol),
    :deep(ul) {
      display: flex;
      flex-direction: column;
      gap: 0.8em;
    }

    :deep(ol) {
      list-style: decimal;
      // margin-right: -2em;
      margin-left: 2em;

      @include break-small {
        // margin-right: -0.251em;
        margin-left: 1.25em;
      }

      li {
        padding-left: 0.5em;
      }
    }

    :deep(ul) {
      // margin-right: -1.5em;
      margin-left: 1.5em;

      @include break-small {
        // margin-right: -0.75em;
        margin-left: 0.75em;
      }

      li {
        padding-left: 1em;
        &::marker {
          content: '·';
        }
      }
    }

    :deep(strong) {
      font-weight: 700;
    }

    :deep(:last-child) {
      margin-bottom: 0;
    }
  }
</style>
